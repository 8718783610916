@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700&display=swap);
body {
  margin: 0;
  background-color: rgba(243,235,229,255);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --grey: #707070;
  --orange: #f68c0d;
}

.Layout_pageWrapper__PUzOm {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100vh;

  background-position: center;
  background-size: cover;
  position: relative;
}

.Layout_content__1t8Hf {
  height: 460px;
  width: 420px;
  position: relative;
}

.Layout_border__2dQC1 {
  background: linear-gradient(to right, var(--orange) 20px, transparent 20px) 0
      0,
    linear-gradient(to right, var(--orange) 20px, transparent 20px) 0 100%,
    linear-gradient(to left, var(--orange) 20px, transparent 20px) 100% 0,
    linear-gradient(to left, var(--orange) 20px, transparent 20px) 100% 100%,
    linear-gradient(to bottom, var(--orange) 20px, transparent 20px) 0 0,
    linear-gradient(to bottom, var(--orange) 20px, transparent 20px) 100% 0,
    linear-gradient(to top, var(--orange) 20px, transparent 20px) 0 100%,
    linear-gradient(to top, var(--orange) 20px, transparent 20px) 100% 100%;
  background-repeat: no-repeat;
  background-size: 43px 100%;
  padding: 20px;
}

.Layout_logo__3WbSF {
  position: absolute;
  bottom: 80px;

  text-decoration: none;
  font-family: 'Nunito Sans';
  font-size: 14px;
  font-weight: 600;
  color: var(--grey);
}
.Layout_logo__3WbSF > img {
  height: 15px;
  width: 82px;
}

.tv {
  margin: 0;
  box-sizing: border-box;
  text-align: center;
  flex-grow: 1;
  width: 100%;
  max-height: 20%;
  justify-content: center;
  flex-direction: row; 
  display: flex;

  /* Responsive to screen size */
  /* @media screen and (max-width: 1000px) {
    display: block;
    flex-direction: column; 
    align-items: center; 
  }
   */
}
.subtitle-overlay {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px;
  border-radius: 5px;
  z-index: 1000;
  margin-bottom: 40px;
}

.subtitle-text {
  color: white;
  font-size: 16px;
  margin: 0;
}


.video-js .vjs-icon-play-previous  {
  margin-left: 20px;
}

.video-js .vjs-fullscreen-control  {
  margin-right: 20px;
}
.vjs-cloudinary-button {
  visibility: hidden;
}
.cld-video-player-player {
  width: 100%;
  height: 100%;
}
video {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 32px;
}
.television {
  flex-grow: 1;
  background-color: black;
  aspect-ratio: 16/9;
  margin: 20px;
  position: relative;
}

.television__top {
  width: 40%;
  position: relative;
  margin-left: 15%;
}

.television__antenna {
  width: 5px;
  height: 100px;
  background-color: #3b3733;
  margin-bottom: -10px;
}

.television__antenna--left {
  transform: rotate(-30deg);
  float: left;
}

.television__antenna--right {
  transform: rotate(30deg);
  float: right;
}

.television__antenna__base {
  height: 20px;
  background-color: #3b3733;
  border-top-left-radius: 48px;
  border-top-right-radius: 48px;
  clear: both;
  position: relative;
  z-index: 2;
}

.television__center {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
  border: solid 3px #c67d81;
  box-shadow: -5px -5px #9f6359;
  border-radius: 18px;
  margin: 0 auto;
  padding: 10px 8px;
  background: rgb(146, 146, 146);
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}
.television__screen {
  width: calc(100% - 75px);
  height: calc(100% - 15px);
  background-color: #000000;
  border-radius: 48px;
  overflow: hidden;
  position: relative;
  display: flex; /* Enable Flexbox */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
}

.iframe__container {
  width: '100%';
  height: '100%';
  margin: 'auto';
}

.cassette-container {
  width: 555px;
  margin: 10px;
  transition: opacity 0.5s ease-in-out;
  display: inline-block;
  transition: width 1s ease;
}

.cassette-container-mobile {
  margin: 10px;
  width: 100%;
  transition: opacity 0.5s ease-in-out;
  display: inline-block;
  transition: width 1s ease;
}

.open {
  width: 30%;
}

.closed {
  display: none; 
}

.narration-panel {
  top: 0;
  left: 0;
  margin: 15px;
  align-items: left;
  width: 100%; 
}

.loader{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999999;
  display: none;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.15);
}

.television_playlist_container {
  position: absolute !important;
  left: 0;
  bottom: 45px;
  height: 100px;
  display: none;
  z-index: 999;
}
.no-js .owl-carousel, .owl-carousel.owl-loaded{
  display: none;
}
.owl-stage-outer,
.owl-stage,
.owl-item {
  width: 100%;
  height: 100px;
}

.control_screen{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 55px);
  z-index: 2;
  background: transparent;
}

.television_all_controls {
  position: absolute;
  /*! left: 0; */
  bottom: 0;
  width: 100%;
  height: 50px;
  display: none;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.8);
}
.television_inner_controls {
  position: absolute;
  width: 90%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.television_inner_controls svg {
  cursor: pointer;
  width: 1.3em;
  height: 1.3em;
}

#cast_icon {
  position: absolute;
  top: 20px;
  right: 30px;
  width: 1.5em;
  height: 1.5em;
  cursor: pointer;
  display: none;
}

.playlist_card {
  height: 100%;
  width: 100px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.playlist_card_image {
  position: absolute;
  width: 100%;
  height: 100%;
}

.playlist_title {
  position: absolute;
  z-index: 9;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  display: flex;
  align-items: center;
  justify-self: center;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}
.playlist_current_title {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 100%;
  height: 30px;
  color: #fff;
  display: none;
  align-items: center;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}

.channel_error_screen {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  display: none;
  transition: all 0.3s ease;
}

.television__channels-wrapper {
  width: 60px;
  height: calc(100% - 40px);
  border-radius: 10px;
  float: right;
  border: 2px solid #333;
}

.television_button {
  margin-top: 20px;
  background: none;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.television_button_area {
  width: 1.9em;
  height: 1.9em;
  border-radius: 50%;
  background: rgb(163, 139, 4);
  border: 4px solid #333;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.television_button_area_1 {
  width: 1.9em;
  height: 1.9em;
  border-radius: 50%;
  background: rgb(223, 146, 32);
  border: 4px solid #333;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.channel_bar_0 {
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgb(20, 95, 13);
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(255, 255, 255);
  box-sizing: border-box;
  box-shadow: 0 0 10px #000000;
  transform: rotate(0deg);
  transition: all 0.5s ease;
  cursor: pointer;
  border-radius: 50%;
  -webkit-user-select: none;
          user-select: none;
}

.on {
  font-size: 0.5em;
  position: absolute;
}

.channel_bar_1 {
  width: 100%;
  height: 10px;
  position: absolute;
  background: #ced7ef;
  box-sizing: border-box;
  box-shadow: 0 0 10px #000000;
  transform: rotate(0deg);
  transition: all 0.5s ease;
  cursor: pointer;
  border-radius: 4px;
}

.televison_sound_line {
  position: relative;
  height: calc(100% - 136px);
  max-height: calc(100% - 136px);
  width: 100%;
  margin-top: 15px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
}
.televison_sound_line .sound_line {
  width: 80%;
  min-height: 5px;
  max-height: 5px;
  margin: 2px 0px;
  background: #333;
}
.television__channels {
  padding: 0;
}

.television__channel {
  list-style-type: none;
  background-color: #7f4b23;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin: 5px;
  float: left;
  position: relative;
}

.television__channel a {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #e6a146;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.television__channel a:active {
  box-shadow: inset rgba(255, 255, 255, 0.6) 0 2px 2px,
    inset rgba(0, 0, 0, 0.15) 0 -2px 5px,
    /* inner shadow */ rgba(100, 100, 100, 0.6) 0 2px 1px,
    rgba(100, 100, 100, 0.6) 0 2px 1px; /* color border */
}

.television__base {
  width: 60%;
  margin: auto;
}

.television__base:after {
  clear: both;
  content: "";
  display: table;
}

.television__foot {
  width: 20px;
  height: 45px;
  margin-top: -20px;
  background-color: #7d4d25;
  border: solid 3px #eed5b6;
  border-radius: 8px;
}

.television__foot--left {
  transform: rotate(30deg);
  float: left;
}

.television__foot--right {
  transform: rotate(-30deg);
  float: right;
}

.p {
  margin-top: 70px;
}
.owl-nav {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.owl-pre{
  position: absolute;
  left: 10px;
  top: auto;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
}

@media (max-width: 767px) {
  .television {
    width: 100%;
    margin: 15px 0 0 0;
  }
  .television__screen {
    width: calc(100% - 40px);
    height: calc(100% - 5px);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    /* display: flex; 
    align-items: center;
    justify-content: center; */
  }
  .television__channels-wrapper{
    width: 40px !important;
    height: 100%;
  }
  .television_button {
    margin-top: 0px;
  }
  .television_inner_controls{
    width: 80%;
    /*! margin: 0 auto !important; */
  }
  .television_inner_controls svg{
    width: 1em !important;
    height: 1em !important;
  }
  .televison_sound_line {
    margin-top: 10px;
    height: calc(100% - 26px);
    max-height: calc(100% - 100px);
    overflow: hidden;
  }
}

.Home_content__dx1t1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: inherit;
  padding: 0px;
  margin: 0px;
}

.Home_userEmail__135cN {
  font-family: 'Nunito Sans';
  font-size: 30px;
  font-weight: 600;
  color: var(--grey);
  margin-top: 30px;
  width: 100%;
  word-break: break-all;
  text-align: center;
}
.Home_logoutBtn__5EahV {
  border: none;
  background: #fafafa;
  box-shadow: 0px 3px 6px #00000029;
  width: 140px;
  height: 30px;
  color: var(--orange);
  cursor: pointer;
  font-weight: bold;
  position: absolute;
  bottom: 100px;
}

.Home_pandaImg__1EGDk {
  width: 450px;
}

.Login_content__2LJnc {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
}

.Login_pageHeader__2c3-U {
  font-family: 'Nunito Sans';
  font-size: 20px;
  font-weight: 600;
  color: var(--grey);
  margin-top: 60px;
}

.Login_btnHeader__2pees {
  font-family: 'Nunito Sans';
  font-size: 16px;
  font-weight: 600;
  color: var(--grey);
  margin: 20px 0 10px 0;
}

.Login_appDescription__1Pv61 {
  font-size: 16px;
  color: #666;
  margin-bottom: 30px;
}
