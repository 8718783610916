.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
}

.pageHeader {
  font-family: 'Nunito Sans';
  font-size: 20px;
  font-weight: 600;
  color: var(--grey);
  margin-top: 60px;
}

.btnHeader {
  font-family: 'Nunito Sans';
  font-size: 16px;
  font-weight: 600;
  color: var(--grey);
  margin: 20px 0 10px 0;
}

.appDescription {
  font-size: 16px;
  color: #666;
  margin-bottom: 30px;
}