.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: inherit;
  padding: 0px;
  margin: 0px;
}

.userEmail {
  font-family: 'Nunito Sans';
  font-size: 30px;
  font-weight: 600;
  color: var(--grey);
  margin-top: 30px;
  width: 100%;
  word-break: break-all;
  text-align: center;
}
.logoutBtn {
  border: none;
  background: #fafafa;
  box-shadow: 0px 3px 6px #00000029;
  width: 140px;
  height: 30px;
  color: var(--orange);
  cursor: pointer;
  font-weight: bold;
  position: absolute;
  bottom: 100px;
}

.pandaImg {
  width: 450px;
}
