@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700&display=swap');

body {
  margin: 0;
  background-color: rgba(243,235,229,255);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --grey: #707070;
  --orange: #f68c0d;
}
