.pageWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100vh;

  background-position: center;
  background-size: cover;
  position: relative;
}

.content {
  height: 460px;
  width: 420px;
  position: relative;
}

.border {
  background: linear-gradient(to right, var(--orange) 20px, transparent 20px) 0
      0,
    linear-gradient(to right, var(--orange) 20px, transparent 20px) 0 100%,
    linear-gradient(to left, var(--orange) 20px, transparent 20px) 100% 0,
    linear-gradient(to left, var(--orange) 20px, transparent 20px) 100% 100%,
    linear-gradient(to bottom, var(--orange) 20px, transparent 20px) 0 0,
    linear-gradient(to bottom, var(--orange) 20px, transparent 20px) 100% 0,
    linear-gradient(to top, var(--orange) 20px, transparent 20px) 0 100%,
    linear-gradient(to top, var(--orange) 20px, transparent 20px) 100% 100%;
  background-repeat: no-repeat;
  background-size: 43px 100%;
  padding: 20px;
}

.logo {
  position: absolute;
  bottom: 80px;

  text-decoration: none;
  font-family: 'Nunito Sans';
  font-size: 14px;
  font-weight: 600;
  color: var(--grey);
}
.logo > img {
  height: 15px;
  width: 82px;
}
